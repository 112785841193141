body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-link-active-color {
  color: inherit!important;
  text-decoration: underline!important;
}

.nav-brand-active-color {
  color: inherit!important;
}

.navbar-toggler {
  border-color: none !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(113,113,139, 100)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.typing-animation-cursor {
  color: inherit!important;
}


.navbar-light .navbar-collapse .navbar-nav .nav-link {
  color: inherit;
}

.navbar-light .navbar-nav .nav-link:hover  {
  color: rgba(113,113,139, 100)!important;
}

.main-content {
  padding-top: 10px;
}

.page-header-padding {
  padding-bottom: 10px;
  width: 315px;
  text-align: left;
}

.creative-page-header {
  margin-bottom: 20px;
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding-top: 20%;
}

.row-spacing {
  padding-top: 20px;
}

.instagram { 
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0,0,0,0.30);
}

.twitter {
  color: #1DA1F2!important;
}

.linkedin {
  color: #0e76a8!important;
}

.react {
  background: #61DBFB!important;
  color: white!important;
}

.github {
  color: inherit;
}

.social-media-link {
  padding-right: 5px;
  text-decoration: underline;
}

.swift {
  background: rgb(252,84,49)!important;
  background: linear-gradient(90deg, rgba(252,84,49,1) 20%, rgba(252,141,65,1) 100%)!important;
  color: white!important;
}

.firebase {
  background: rgb(255,205,50)!important;
  background: -moz-linear-gradient(90deg, rgba(255,205,50,1) 20%, rgba(245,130,13,1) 100%)!important;
  background: -webkit-linear-gradient(90deg, rgba(255,205,50,1) 20%, rgba(245,130,13,1) 100%)!important;
  background: linear-gradient(90deg, rgba(255,205,50,1) 20%, rgba(245,130,13,1) 100%)!important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffcd32",endColorstr="#f5820d",GradientType=1);
}

.rectangle {
  width: 315px;
  height: 102%;

  border: 2px solid;
  box-sizing: border-box;
}

.col-padding {
  padding-bottom: 50px;
}

.icon-resize {
  height: 80px;
  width: 80px;
}

.icon-wrap {
  text-align: center;
  padding-top: 10px;
}

.project-content-description {
  height: 88px;
}

.project-content, 
.project-content-bottom {
  padding-top: 10px;
}

.project-content-title {
  height: 65px;
}

.project-content-description {
  height: 110px;
  /* padding-top: 15px; */
}

.title-heading {
  margin-bottom: 0;
}

.project-type {
  font-weight: 300;
}
.project-content-bottom {
  height: 110px;
}

.project-box {
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.project-link {
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration-line: underline;
  color: inherit;
  padding-right: 20px;
}

.project-tech {
  position: absolute;
  bottom: 0;
  padding-left: 10px;
}

.project-tech-pill-bottom {
  margin-bottom: 0px;
}

.badge {
  margin-right: 5px;
  vertical-align: text-top!important;
}

.blog-list-link {
  color: inherit;
  text-decoration: underline;
}

.blog-post-info {
  padding-bottom: 5px;
}

.blog-post-line {
  border: 0.5px solid;
  box-sizing: content-box;
}

.blog-post-info-metadata {
  font-size: smaller;
}

.blog-post-back-link {
  padding-bottom: 10px;
  
}

.blog-post-content {
  padding-top: 15px;
}

.post-list-padding {
  padding-bottom: 30px;
}


